import { NumberDetailConnectedIntegrationsSection } from './NumberDetailConnectedIntegrationsSection/NumberDetailConnectedIntegrationsSection';
import { NumberDetailWhatsAppIntegrationSection } from './NumberDetailWhatsAppIntegrationSection/NumberDetailWhatsAppIntegrationSection';

import { Spacer } from '@aircall/tractor-v2';
import { WhatsappRegistrationState } from '@generated/globalTypes';

interface NumberDetailIntegrationsPageProps {
  readonly whatsappStatus: WhatsappRegistrationState;
}

export function NumberDetailIntegrationsPage({
  whatsappStatus,
}: Readonly<NumberDetailIntegrationsPageProps>) {
  return (
    <Spacer fluid direction='vertical' space='m'>
      <NumberDetailWhatsAppIntegrationSection whatsappStatus={whatsappStatus} />
      <NumberDetailConnectedIntegrationsSection />
    </Spacer>
  );
}
