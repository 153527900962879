import { gql } from '@apollo/client';

export const GET_WHATSAPP_REGISTRATION_STATUS = gql`
  query getWhatsappRegistrationStatus($numberUUID: String!) {
    getWhatsappRegistrationStatus(numberUUID: $numberUUID) {
      status
      offlineReason
    }
  }
`;
