import { LOOKER_FILTER_KEY } from './Looker.decl';

export const LOOKER_PAGES_FILTERS = 'looker-pages-filters';

export const FILTER_KEY_MAPPER: Record<string, string> = {
  [LOOKER_FILTER_KEY.DATE]: 'date',
  [LOOKER_FILTER_KEY.LINES]: 'lines',
  [LOOKER_FILTER_KEY.NUMBERS]: 'numbers',
  [LOOKER_FILTER_KEY.TAGS]: 'tags',
  [LOOKER_FILTER_KEY.TEAMS]: 'teams',
  [LOOKER_FILTER_KEY.USERS]: 'users',
  [LOOKER_FILTER_KEY.AIRCALL_NUMBER]: 'aircall-number',
  [LOOKER_FILTER_KEY.BUSINESS_HOURS]: 'business-hours',
  [LOOKER_FILTER_KEY.HOURS]: 'hours',
  [LOOKER_FILTER_KEY.CALL_ID]: 'call-id',
  [LOOKER_FILTER_KEY.CALL_TYPE]: 'call-type',
  [LOOKER_FILTER_KEY.CUSTOMER_NUMBER]: 'customer-number',
  [LOOKER_FILTER_KEY.DATE_BREAKDOWN]: 'date-breakdown',
  [LOOKER_FILTER_KEY.INBOUND_SLA]: 'inbound-sla',
  [LOOKER_FILTER_KEY.MISSED_REASONS]: 'missed',
  [LOOKER_FILTER_KEY.SLA]: 'sla',
  [LOOKER_FILTER_KEY.SLA_GOAL]: 'sla-goal',
  [LOOKER_FILTER_KEY.TIMEZONE]: 'timezone',
  [LOOKER_FILTER_KEY.NEXT_STATUS]: 'next-status',
  [LOOKER_FILTER_KEY.STATUS]: 'status',
  [LOOKER_FILTER_KEY.OVERVIEW_METRICS]: 'overview-metrics',
  [LOOKER_FILTER_KEY.EXCLUDE_FROM_SLA]: 'exclude-from-sla',
  [LOOKER_FILTER_KEY.MIN_CONNECTED]: 'min-connected',
  [LOOKER_FILTER_KEY.OUTBOUND_CONNECTED]: 'outbound-connected',
  [LOOKER_FILTER_KEY.UNANSWERED_REASON]: 'unanswered-reason',
  [LOOKER_FILTER_KEY.IVR_BRANCH]: 'ivr-branch',
  [LOOKER_FILTER_KEY.DURATION]: 'duration',
  [LOOKER_FILTER_KEY.CALL_ROUTED_TO_TEAM]: 'teams',
  [LOOKER_FILTER_KEY.USERS_BELONGING_TO_TEAM]: 'users-belonging-to-team',
};
