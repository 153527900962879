/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useEffect, useState } from 'react';

import { MetaEmbeddedSignupButtonProps } from './MetaEmbeddedSignupButton.decl';

import { Button, Link } from '@aircall/tractor-v2';
import {
  getEmbeddedSignupListener,
  launchEmbeddedSignup,
  loadFacebookSDKScript,
} from '@components/MetaEmbeddedSignupButton/helpers/metaEmbeddedSignup.helpers';
import { WhatsappRegistrationState } from '@generated/globalTypes';
import { useTranslation } from 'react-i18next';

export function MetaEmbeddedSignupButton({
  onEmbeddedSignup,
  status,
}: Readonly<MetaEmbeddedSignupButtonProps>) {
  const { t } = useTranslation();
  const [enableFacebookButton, setEnableFacebookButton] = useState(false);

  const embeddedSignupListener = useCallback(
    () => getEmbeddedSignupListener(onEmbeddedSignup),
    [onEmbeddedSignup]
  );

  useEffect(() => {
    loadFacebookSDKScript()
      .then(() => {
        setEnableFacebookButton(status !== WhatsappRegistrationState.CREATING);
      })
      .catch(() => {
        setEnableFacebookButton(false);
      });
  }, [status]);

  useEffect(() => {
    window.addEventListener('message', embeddedSignupListener);
    return () => {
      window.removeEventListener('message', embeddedSignupListener);
    };
  }, [embeddedSignupListener]);

  return (
    <>
      <Button
        disabled={!enableFacebookButton}
        onClick={launchEmbeddedSignup}
        data-test='facebook-button'
        variant={status === WhatsappRegistrationState.ONLINE ? 'critical' : 'primary'}
      >
        {status === WhatsappRegistrationState.ONLINE
          ? t('number_details.integrations.whatsapp_integration.deactivate_button')
          : t('number_details.integrations.whatsapp_integration.facebook_button')}
      </Button>
      <Button
        as={Link}
        data-test='download-data-modal-close'
        href={t('number_details.integrations.whatsapp_integration.learn_more_link')}
        target='_blank'
        variant='secondary'
        mode='link'
      >
        {t('number_details.integrations.whatsapp_integration.learn_more_button')}
      </Button>
    </>
  );
}
