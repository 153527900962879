import { useEffect, useRef } from 'react';

import { convertISOtoEpoch } from './utils/tandem.helpers';

import { TANDEM_APP_ID, TANDEM_ENVIRONMENT } from '@constants/environment.constants';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';

/**
 * This hook setups the Tandem AI script and adds the Tandem settings data into the window.
 * Tandem is a 3rd party tool we are using to help users with our onboarding process via
 * AI tooltips. See JIRA ticket [GROW-518] for more details.
 */
export const useInitTandemAiScripts = (): void => {
  const { dashboardEnableTandemAi } = useFeaturesFlags();
  const { currentUser } = useGlobalData();
  const isTandemAiInitialized = useRef(false);

  useEffect(() => {
    // Only initialize tandem if feature flag is enabled and it hasn't already been initialized
    if (!dashboardEnableTandemAi || isTandemAiInitialized.current) {
      return;
    }

    // Setup Tandem AI settings data
    const createdAt = convertISOtoEpoch(currentUser.createdAt);
    const tandemSettings = {
      app_id: TANDEM_APP_ID,
      environment: TANDEM_ENVIRONMENT,
      user: {
        id: currentUser.email,
        name: `${currentUser.firstName} ${currentUser.lastName}`,
        email: currentUser.email,
        custom_properties: {
          created_date: createdAt,
        },
      },
    };

    // Initialize Tandem AI script to add settings data into window.
    const tandemSettingsScript = document.createElement('script');
    tandemSettingsScript.textContent = `window.tandemSettings = ${JSON.stringify(tandemSettings)}`;

    // Initialize Tandem AI script
    const tandemScript = document.createElement('script');
    tandemScript.type = 'module';
    tandemScript.src = 'https://widget.usetandem.ai';
    tandemScript.defer = true;

    // Append Tandem AI scripts to the head of the document
    document.head.appendChild(tandemSettingsScript);
    document.head.appendChild(tandemScript);

    isTandemAiInitialized.current = true;
  }, [dashboardEnableTandemAi, currentUser, isTandemAiInitialized]);
};
