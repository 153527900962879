import { IDType } from '@aircall/tractor-v2';
import { WhatsappRegistrationState } from '@generated/globalTypes';
import { NumberDetailQuery_numberDetail } from '@generated/NumberDetailQuery';
import { SearchLineByIDQuery_searchLines_items } from '@generated/SearchLineByIDQuery';
import { Location } from 'react-router-dom';

export enum NUMBER_DETAIL_TABS {
  CALL_DISTRIBUTION = 'call-distribution',
  SETTINGS = 'settings',
  TEAMS_AND_USERS = 'teams-and-users',
  BUSINESS_HOURS = 'business-hours',
  INTEGRATIONS = 'integrations',
  REGISTRATION_STATUS = 'registration-status',
}

export type ProofOfIdRedirectedState = Location & {
  state: { proofOfIdSubmitted?: boolean };
};

export interface ProofOfIdBannerProps {
  numberDetail: NumberDetailQuery_numberDetail;
}

export interface NumberDetailHeaderProps {
  activeTabId: string;
  children: (JSX.Element | null)[];
  shouldShowRegistrationStatus: boolean;
  line: SearchLineByIDQuery_searchLines_items | undefined;
  numberDetail: NumberDetailQuery_numberDetail;
  whatsappStatus: WhatsappRegistrationState;
  onTabChange: (tabId: IDType) => void;
}
