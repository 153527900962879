import { useState } from 'react';

import {
  ChevronDownOutlined,
  ChevronRightOutlined,
  StopFilled,
  WarningFilled,
} from '@aircall/icons';
import { Icon, Tag, Spacer, Typography, Box, Link } from '@aircall/tractor-v2';
import { MetaEmbeddedSignupButton } from '@components/MetaEmbeddedSignupButton';
import { Loading, Paper } from '@dashboard/library';
import { WhatsappRegistrationState } from '@generated/globalTypes';
import { useFeaturesFlags } from '@hooks/useFeatures/useFeaturesFlags';
import { Trans, useTranslation } from 'react-i18next';

function StatusMessage({ whatsappStatus }: { readonly whatsappStatus: WhatsappRegistrationState }) {
  const [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation();

  const toggleContent = () => {
    setIsOpen((prevState) => !prevState);
  };

  switch (whatsappStatus) {
    case WhatsappRegistrationState.NOT_REGISTERED:
      return (
        <Tag variant='white' size='small'>
          <Icon component={WarningFilled} size={18} color='warning-500' />
          <Typography variant='bodyMediumS' color='text-base' as='p' margin='0'>
            <Trans
              i18nKey='number_details.integrations.whatsapp_integration.status.not_registered.title'
              components={{
                customLink: (
                  <Link
                    color='inherit'
                    fontSize='inherit'
                    target='_blank'
                    href={t(
                      'number_details.integrations.whatsapp_integration.status.not_registered.link'
                    )}
                  />
                ),
              }}
            />
          </Typography>
        </Tag>
      );
    case WhatsappRegistrationState.CREATING:
      return (
        <Box border='1px solid #eee' borderRadius='25px'>
          <Typography
            variant='bodyMediumS'
            as='p'
            padding='50px'
            textAlign={{
              _: 'center',
            }}
          >
            <Spacer alignItems='center' space='s'>
              <Loading size={24} data-test='saving-icon' />
            </Spacer>
            <br />
            <br />
            <Typography variant='bodyMediumM'>
              {t('number_details.integrations.whatsapp_integration.status.pending.title')}
            </Typography>
            <br />
            <Typography variant='bodyMediumS'>
              {t('number_details.integrations.whatsapp_integration.status.pending.subtitle')}
            </Typography>
          </Typography>
        </Box>
      );
    case WhatsappRegistrationState.ONLINE:
      return (
        <Typography variant='bodyMediumS' color='text-base' as='p' margin='0'>
          <Spacer
            direction='horizontal'
            space='xxxs'
            pt='m'
            onClick={toggleContent}
            marginBottom='10px'
            data-test='template-toggle-button'
          >
            <Typography variant='bodySemiboldS' color='text-base' as='p' margin='0'>
              {t(
                'number_details.integrations.whatsapp_integration.status.verified.templated_title'
              )}
            </Typography>

            {isOpen ? (
              <ChevronDownOutlined size={15} style={{ marginTop: '3px' }} />
            ) : (
              <ChevronRightOutlined size={15} style={{ marginTop: '3px' }} />
            )}
          </Spacer>
          {isOpen && (
            <>
              <br />
              <Trans
                i18nKey='number_details.integrations.whatsapp_integration.status.verified.templated_subtitle'
                components={{
                  ol: <ol />,
                  li: <li />,
                  bold: <strong />,
                }}
              />
              <br />
            </>
          )}
        </Typography>
      );
    case WhatsappRegistrationState.OFFLINE:
      return (
        <Box border='1px solid #eee' borderRadius='25px'>
          <Typography
            variant='bodyMediumS'
            as='p'
            padding='50px'
            textAlign={{
              _: 'center',
            }}
          >
            <Spacer alignItems='center' space='s'>
              <Icon component={StopFilled} color='critical-500' size={45} />
            </Spacer>
            <br />
            <br />
            <Typography variant='headingBoldM'>
              {t('number_details.integrations.whatsapp_integration.status.rejected.title')}
            </Typography>
            <br />
            <Typography variant='bodyMediumS'>
              {t('number_details.integrations.whatsapp_integration.status.rejected.subtitle')}
            </Typography>
          </Typography>
        </Box>
      );
    default:
      return null;
  }
}

interface NumberDetailWhatsAppIntegrationSectionProps {
  whatsappStatus: WhatsappRegistrationState;
}

export function NumberDetailWhatsAppIntegrationSection({
  whatsappStatus,
}: NumberDetailWhatsAppIntegrationSectionProps) {
  const { t } = useTranslation();
  const { enableWhatsappIntegration } = useFeaturesFlags();
  const [isOpen, setIsOpen] = useState(true);

  const onEmbeddedSignup = (phoneNumberId: string, wabaId: string) => {
    // WABA ID will be used to create the Twilio Sender on backend
    // eslint-disable-next-line no-console
    console.log('onEmbeddedSignup', phoneNumberId, wabaId);
  };

  const toggleContent = () => {
    setIsOpen((prevState) => !prevState);
  };

  return enableWhatsappIntegration ? (
    <Paper
      title={t('number_details.integrations.whatsapp_integration.title')}
      subtitle={<Trans i18nKey='number_details.integrations.whatsapp_integration.subtitle' />}
    >
      <Box minWidth='0' style={{ wordBreak: 'break-word', textAlign: 'start' }}>
        <Typography variant='bodyMediumS' color='text-base' as='p' margin='0'>
          <Spacer
            direction='horizontal'
            space='xxxs'
            pt='m'
            onClick={toggleContent}
            marginBottom='10px'
            data-test='toggle-button'
          >
            <Typography variant='bodySemiboldS' color='text-base' as='p' margin='0'>
              {t('number_details.integrations.whatsapp_integration.how_to_proceed')}
            </Typography>

            {isOpen ? (
              <ChevronDownOutlined size={15} style={{ marginTop: '3px' }} />
            ) : (
              <ChevronRightOutlined size={15} style={{ marginTop: '3px' }} />
            )}
          </Spacer>
          {isOpen && (
            <>
              <br />
              <Trans
                i18nKey='number_details.integrations.whatsapp_integration.how_to_proceed_subtitle'
                components={{
                  ol: <ol />,
                  li: <li />,
                  bold: <strong />,
                }}
              />
              <br />
            </>
          )}
        </Typography>
      </Box>
      <Box minWidth='0' style={{ wordBreak: 'break-word', textAlign: 'start' }}>
        <StatusMessage whatsappStatus={whatsappStatus} />
      </Box>
      <br />
      <Spacer fluid direction='vertical' space='xxxs'>
        <MetaEmbeddedSignupButton onEmbeddedSignup={onEmbeddedSignup} status={whatsappStatus} />
      </Spacer>
    </Paper>
  ) : null;
}
