import { ErrorInfo } from 'react';

import { datadogRum } from '@datadog/browser-rum';

export function addReactError(name: string, error: Error, info: ErrorInfo): void {
  const datadogRumError = new Error(error.message);
  datadogRumError.name = name;
  datadogRumError.stack = info.componentStack;
  datadogRumError.cause = error;
  datadogRum.addError(datadogRumError);
}
