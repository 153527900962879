import { gql } from '@apollo/client';

export const FEATURES_FLAGS_QUERY = gql`
  query FeaturesFlagsQuery {
    featureFlags @rest(type: "RestFeatureFlag", path: "feature_flags", endpoint: "v3") {
      advancedAiPackage
      dashboardExtensionEnableNewAssetsPage
      dashboardExtensionEnableConversationsCenterCallScoring
      dashboardExtensionEnableConversationsCenterAiQuestions
      dashboardExtensionEnableConversationsCenterImprovedScorecardsManagement
      dashboardExtensionEnableConversationsCenterAma
      dashboardExtensionEnableConversationsCenterNextActionsItems
      showLookerInAnalytics
      showLegacyAnalytics
      dashboardHideNumberAvailabilityStatus
      enableCallHistoryFeedbackButton
      analyticsAvailabilitiesDashboard
      showAdvancedV4Analytics
      showCallQualityDashboard
      showCallQualityDashboardPro
      showCallQualityDashboardEssentials
      showGoalOrientedForProOnAnalyticsPlusException
      enablePermissionsNotifications
      dashboardOnboardingFirefoxExtensionDisplayed
      disableFrontendTracking
      dashboardSmartflowsEnabled
      useSamlAuthentication
      dashboardPlanUpgradeEnabled
      useFirstPaymentCheck
      dashboardPlanChangesEnabled
      useInternalApiCustomerProvisioningQueries
      useInternalApiToUpdateBillingFieldsInDashboard
      useInternalApiCountriesCurrenciesList
      enableTrialAddonsForAdvancedMessaging
      enableTrialAddonsForAircallAi
      enableTrialAddonsForAircallSurveys
      enableTrialAddonsForAnalyticsPlus
      enableSelfPurchaseAddonFlow
      enableSelfPurchaseAddonSubscriptionButton
      useSmartflows
      dashboardEnableSmartflowsMigration
      fraudEmailChangeValidation
      fraudPasswordChangeValidation
      useRevampedPlanSection
      enableAircallWorkspaceMigration
      enableStarterPlan
      enableStarterPlanForAllCountries
      enableDiscountDuringSelfCancellation
      dashboardShowStarterControl
      dashboardEnablePricingAwareness
      useLicenseBasedSubscription
      useSelfServiceCancellation
      keepLoadingDashboardOnIroncladFailure
      selfCancellationWithAutoApprovalEnabled
      selfCancellationWithApprovalRequiredEnabled
      useAuInvoiceEndpoints
      ringToViaApiEnabled
      dashboardAgentsCreationDisplayNewMaxWarning
      dashboardNumbersCreationDisplayNewMaxWarning
      dashboardListViewFilters
      workatoIframeShowOnlyConnectButton
      enablePusherConfig
      enableGainsightEvents
      useEmailMfa
      enableGclDirectDebitMandate
      dashboardEnableCallTimelinePage
      dashboardBlockDirectDebitOptionInCaseSelfSubscription
      dashboardExtractReport
      showLivefeedDeprecationMessage
      useEmailMfa
      enableGclDirectDebitMandate
      dashboardEnableCallTimelinePage
      dashboardEnableTandemAi
      dashboardBlockDirectDebitOptionInCaseSelfSubscription
      dashboardExtractReport
      dashboardEnableA2P10DlcRegistrationStatus
      dashboardEnabledNewSidebar
      dashboardHideActivityfeed
      dashboardV2BlockCoaching
      advancedAiPackageSentimentAnalysis
      flowEditorEnableSetupFlows
      flowEditorEnableExperimentalWidget
      flowEditorEnableWaitingExperienceWidget
      flowEditorEnableAutomaticCallback
      newIntegrationDashboardUi
      showOnboardingTrial
      enableWhatsappIntegration
      dashboardStarterEnforceTos
      enableHeymarketAllAdvMessagingProducts
      enableAuthV2Endpoints
      enableAircallWorkspace
      useRoleHierarchies
      applySupervisorRestrictions
      enableAirway
      enableRealtimeTranscription
      dashboardConversationsCenterPlaybookBeta
      dashboardConversationsCenterRealTimeAssistant

      # Related to Dashboard Extension
      # should stay because of the logic present in ModuleFederationProvider.tsx
      dashboardExtensionEnableInternalTestExt
      dashboardExtensionEnableFlowEditorExtension
      dashboardExtensionEnableNumberExtension
      dashboardExtensionEnableIntegrationExtension
      dashboardExtensionEnableConversationsCenterExt
      dashboardExtensionEnableCallTimelineExtension
      dashboardExtensionEnableCalendarMgmtExtension
      dashboardExtensionEnableAccountExtension
      dashboardExtensionEnableTeamExtension
      dashboardExtensionEnableUserExtension
      dashboardExtensionEnableLiveMonitoringExtension
      dashboardExtensionEnableConversationsCenterInsightBoard
      dashboardExtensionEnableVoiceVirtualAgentExtension
      dashboardExtensionEnableAnalyticsExtension
    }
  }
`;
